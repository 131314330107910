$(document).foundation();


var mobileDropdown = $('.mobile-nav-header .dropdown-container');
var headerNav = $('.header nav ul');
mobileDropdown.on('click', function(){
 if(headerNav.hasClass('mobile-active')){
  headerNav.removeClass('mobile-active');
 }else{
  headerNav.addClass('mobile-active');
 }
});

function gotoSection( sectionName, useGoto ){



 var form = $('<form>', {'action': window.location.href, 'method':'POST'});
 if(useGoto){
  form.append( $('<input>', {
   'name': 'goto',
   'value': sectionName ,
   'type': 'hidden'
   }) );
 }else{
  form.append( $('<input>', {
   'name': 'nav',
   'value': sectionName ,
   'type': 'hidden'
   }) );
 }

  form.appendTo('body');
  form.submit();
}

var aboutSuicide = $('.about-suicide-section');
if(aboutSuicide.length){
 var currentpage = aboutSuicide;
 var sections = currentpage.find('.section-item');
 var wrapper = currentpage.find('.section-wrapper');
 var sectionTotal = sections.length;
 var current = 1;
 var currentSection = $(sections.get(current-1));
 var prevBtn = currentpage.find('#prev-btn');
 var nextBtn = currentpage.find('#next-btn');

 var player = videojs('my-video');

 TweenMax.to( wrapper, 0, {height: currentSection.outerHeight() });
 TweenMax.to(  currentSection, .3, {autoAlpha: 1, y:0} );

 $(window).on('resize load', function(){
  TweenMax.to( wrapper, 0, {height: currentSection.outerHeight() });
 });

 prevBtn.on('click', function(e){

  e.preventDefault();

  if(current > 1){

   TweenMax.to(  currentSection, 0, {autoAlpha: 0} );

   current--;
   currentSection = $(sections.get(current -1));

   if(current == 1){
    TweenMax.to(  prevBtn, .3, {autoAlpha: 0} );
   }

   TweenMax.to( wrapper, 0, {height: currentSection.outerHeight() });
   TweenMax.to(  currentSection, .3, {autoAlpha: 1, y:0} );

   if(player) player.pause();

  }

 });

 nextBtn.on('click', function(e){
  e.preventDefault();

  if(current == sectionTotal){

   gotoSection('about');

  }else{
    TweenMax.to(  currentSection, 0, {autoAlpha: 0} );

    current++;
    currentSection = $(sections.get(current -1));

    TweenMax.to( wrapper, 0, {height: currentSection.outerHeight() });
    TweenMax.to(  currentSection, .3, {autoAlpha: 1, y:0} );
    TweenMax.to(  prevBtn, .3, {autoAlpha: 1} );

    if(player) player.pause();


  }

 });

}


//ABOUT QPR

var aboutQPR = $('.about-qpr-section');
if(aboutQPR.length){
 var currentpage = aboutQPR;
 var sections = currentpage.find('.section-item');
 var wrapper = currentpage.find('.section-wrapper');
 var sectionTotal = sections.length;
 var current = 1;
 var currentSection = $(sections.get(current-1));
 var prevBtn = currentpage.find('#prev-btn');
 var nextBtn = currentpage.find('#next-btn');

 var player = videojs('my-video');

 TweenMax.to( wrapper, 0, {height: currentSection.outerHeight() });
 TweenMax.to(  currentSection, .3, {autoAlpha: 1, y:0} );

 $(window).on('resize load', function(){
  TweenMax.to( wrapper, 0, {height: currentSection.outerHeight() });
 });

 prevBtn.on('click', function(e){

  e.preventDefault();

  if(current > 1){

   TweenMax.to(  currentSection, 0, {autoAlpha: 0} );

   current--;
   currentSection = $(sections.get(current -1));

   if(current == 1){
    TweenMax.to(  prevBtn, .3, {autoAlpha: 0} );
   }

   TweenMax.to( wrapper, 0, {height: currentSection.outerHeight() });
   TweenMax.to(  currentSection, .3, {autoAlpha: 1, y:0} );

   if(player) player.pause();

  }

 });

 nextBtn.on('click', function(e){
  e.preventDefault();

  if(current == sectionTotal){

   gotoSection('qprintro');

  }else{
    TweenMax.to(  currentSection, 0, {autoAlpha: 0} );

    current++;
    currentSection = $(sections.get(current -1));

    TweenMax.to( wrapper, 0, {height: currentSection.outerHeight() });
    TweenMax.to(  currentSection, .3, {autoAlpha: 1, y:0} );
    TweenMax.to(  prevBtn, .3, {autoAlpha: 1} );

    if(player) player.pause();


  }

 });
}

var learnQPR = $('.learn-qpr-section');
if(learnQPR.length){
 var currentpage = learnQPR;
 var sections = currentpage.find('.section-item');
 var wrapper = currentpage.find('.section-wrapper');
 var sectionTotal = sections.length;
 var current = 1;
 var currentSection = $(sections.get(current-1));
 var prevBtn = currentpage.find('#prev-btn');
 var nextBtn = currentpage.find('#next-btn');

 var player = videojs('my-video');

 TweenMax.to( wrapper, 0, {height: currentSection.outerHeight() });
 TweenMax.to(  currentSection, .3, {autoAlpha: 1, y:0} );

 $(window).on('resize load', function(){
  TweenMax.to( wrapper, 0, {height: currentSection.outerHeight() });
 });

 prevBtn.on('click', function(e){

  e.preventDefault();

  if(current > 1){

   TweenMax.to(  currentSection, 0, {autoAlpha: 0} );

   current--;
   currentSection = $(sections.get(current -1));

   if(current == 1){
    TweenMax.to(  prevBtn, .3, {autoAlpha: 0} );
   }

   TweenMax.to( wrapper, 0, {height: currentSection.outerHeight() });
   TweenMax.to(  currentSection, .3, {autoAlpha: 1, y:0} );

   if(player) player.pause();

  }

 });

 nextBtn.on('click', function(e){
  e.preventDefault();

  if(current == sectionTotal){

   gotoSection('lecture');

  }else{
    TweenMax.to(  currentSection, 0, {autoAlpha: 0} );

    current++;
    currentSection = $(sections.get(current -1));

    TweenMax.to( wrapper, 0, {height: currentSection.outerHeight() });
    TweenMax.to(  currentSection, .3, {autoAlpha: 1, y:0} );
    TweenMax.to(  prevBtn, .3, {autoAlpha: 1} );

    if(player) player.pause();


  }

 });
}






var thingsSection = $('.things-section');
if(thingsSection.length){
 var currentpage = thingsSection;
 var sections = currentpage.find('.section-item');
 var wrapper = currentpage.find('.section-wrapper');
 var sectionTotal = sections.length;
 var current = 1;
 var currentSection = $(sections.get(current-1));
 var prevBtn = currentpage.find('#prev-btn');
 var nextBtn = currentpage.find('#next-btn');

 //var player = videojs('my-video');

 TweenMax.to( wrapper, 0, {height: currentSection.outerHeight() });
 TweenMax.to(  currentSection, .3, {autoAlpha: 1, y:0} );

 $(window).on('resize load', function(){
  TweenMax.to( wrapper, 0, {height: currentSection.outerHeight() });
 });

 prevBtn.on('click', function(e){

  e.preventDefault();

  if(current > 1){

   TweenMax.to(  currentSection, 0, {autoAlpha: 0} );

   current--;
   currentSection = $(sections.get(current -1));

   if(current == 1){
    TweenMax.to(  prevBtn, .3, {autoAlpha: 0} );
   }

   TweenMax.to( wrapper, 0, {height: currentSection.outerHeight() });
   TweenMax.to(  currentSection, .3, {autoAlpha: 1, y:0} );

  }

 });

 nextBtn.on('click', function(e){
  e.preventDefault();

  if(current == sectionTotal){

   gotoSection("things_to_remember");

  }else{
    TweenMax.to(  currentSection, 0, {autoAlpha: 0} );

    current++;
    currentSection = $(sections.get(current -1));

    TweenMax.to( wrapper, 0, {height: currentSection.outerHeight() });
    TweenMax.to(  currentSection, .3, {autoAlpha: 1, y:0} );
    TweenMax.to(  prevBtn, .3, {autoAlpha: 1} );


  }

 });
}






var quizSection = $('.quiz-section');
if(quizSection.length){
 var currentpage = quizSection;
 var sections = currentpage.find('.section-item');
 var wrapper = currentpage.find('.section-wrapper');
 var sectionTotal = sections.length;
 var current = 1;
 var currentSection = $(sections.get(current+1));
 var pageNav = currentpage.find('.page-navigation');
 var prevBtn = currentpage.find('#prev-btn');
 var nextBtn = currentpage.find('#next-btn');


 var mobileStatus = quizSection.find('.mobile-status .current');
 var questionStatus = quizSection.find('.question-status ul li');
 var currentQuestion = 1;
 var totalQuestions = questionStatus.length;
 var questionItems = quizSection.find('.question-item');
 var currentQuestionItem =$(questionItems.get(0));
 var nextQuestion = quizSection.find('#next-question-btn');
 var prevQuestion = quizSection.find('#prev-question-btn');



 function updateStatus( q ){

  var statusOpactiy = .5;


  mobileStatus.html(q);
  questionStatus.removeClass('active');
  questionStatus.each(function(i){
   if( i+1 == q){
    statusOpactiy = 1;
    $(this).addClass('active');
   }
   $(this).css('opacity', statusOpactiy);
  });

 }

 function updateQuestion( q ){

   currentQuestionItem.hide();
   currentQuestionItem = $(questionItems.get(q-1));
   currentQuestionItem.show();
   TweenMax.to( wrapper, 0, {height: currentSection.outerHeight() });

 }

  prevQuestion.on('click', function(e){
   e.preventDefault();

   if(currentQuestionItem == 2){
    TweenMax.to( prevQuestion, .3, {autoAlpha:0});
   }

   currentQuestion--;
   updateQuestion(currentQuestion);
   updateStatus(currentQuestion);

  });

  nextQuestion.on('click', function(e){
   e.preventDefault();
   if( currentQuestionItem.find('.question-answer.selected').length ){

    if(currentQuestion == 1){
     TweenMax.to( prevQuestion, .3, {autoAlpha:1});
    }
    if(currentQuestion < totalQuestions ){
     currentQuestion++;
     updateQuestion(currentQuestion);
     updateStatus(currentQuestion);
    }else{

     var form = $('<form>', {'action': window.location.href, 'method':'POST'});

     questionItems.each(function(item, el){
      var $el = $(el);
      var val = $el.find('.question-answer.selected').data('val');
      form.append( $('<input>', {
       'name': 'answers[]',
       'value': val ,
       'type': 'hidden'
       }) );
     });

     form.append( $('<input>', {
      'name': 'survey',
      'value': 'results' ,
      'type': 'hidden'
      }) );
     form.appendTo('body');
     form.submit();


    }


   }else{
    alert('Please select an answer.');
   }
  });






 updateQuestion(1);
 updateStatus(1);

 $('.question-answer').on('click', function(){

  var $this = $(this);
  if( !$this.hasClass('selected') ){
   $this.siblings().removeClass('selected');
   $this.addClass('selected');
  }

 });









 //var player = videojs('my-video');
 pageNav.hide();
 TweenMax.to( wrapper, 0, {height: currentSection.outerHeight() });
 TweenMax.to(  currentSection, .3, {autoAlpha: 1, y:0} );

 $(window).on('resize load', function(){
  TweenMax.to( wrapper, 0, {height: currentSection.outerHeight() });
 });

 prevBtn.on('click', function(e){

  e.preventDefault();

  if(current > 1){

   TweenMax.to(  currentSection, 0, {autoAlpha: 0} );

   current--;
   currentSection = $(sections.get(current -1));

   if(current == 1){
    TweenMax.to(  prevBtn, .3, {autoAlpha: 0} );
   }

   TweenMax.to( wrapper, 0, {height: currentSection.outerHeight() });
   TweenMax.to(  currentSection, .3, {autoAlpha: 1, y:0} );

  }

 });

 nextBtn.on('click', function(e){
  e.preventDefault();

  if(current == sectionTotal){

   location.href='rebrand.php?section=quiz';

  }else{
    TweenMax.to(  currentSection, 0, {autoAlpha: 0} );

    current++;
    currentSection = $(sections.get(current -1));

    TweenMax.to( wrapper, 0, {height: currentSection.outerHeight() });
    TweenMax.to(  currentSection, .3, {autoAlpha: 1, y:0} );
    TweenMax.to(  prevBtn, .3, {autoAlpha: 1} );


  }

 });
}


var quizResults = $('.quiz-results-section');
if(quizResults.length){
 $('#result-lecture').on('click', function(e){
  e.preventDefault();
  gotoSection('5', true);
 });
 $('#result-quiz').on('click', function(e){
  e.preventDefault();
  gotoSection('8', true);
 });
 $('#result-complete').on('click', function(e){
  e.preventDefault();
  gotoSection('quiz');
 });
}



var emailModal = $('#emailModal');
emailModal.on('closed.zf.reveal', function () {
 emailModal.find('input[type="text"],textarea').val('');
 emailModal.find('.callout').hide();
});

var emailBookModal = $('#emailBookModal');
emailBookModal.on('closed.zf.reveal', function () {
 emailBookModal.find('input[type="text"],textarea').val('');
 emailBookModal.find('.callout').hide();
});

$(document).on("submit", "form.ajaxform", function(e){
    e.preventDefault();

    var $form = $(this);

    $.ajax({
        url     : $form.attr('action'),
        type    : $form.attr('method'),
        data    : $form.serialize(),
        success : function( data ) {
         $form.find('input[type="text"],textarea').val('');
         $form.find('.callout').show();
        }
    });

    return  false;
});

$('.custom-nav a').on('click', function(e){
 e.preventDefault();
 gotoSection( $(this).data('val'), true );
})
